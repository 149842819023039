import "./PYPNItinerario.css";
import { useState, useEffect } from "react";
import React, { Fragment } from 'react';
import { Col, Container, Row } from "react-bootstrap";
import EstadoCruce from '../../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../../components/Navegacion/Navegacion";
import VolverInicio from "../../../components/VolverInicio/VolverInicio";
import Chat from "../../../components/Chat/Chat";
import Footer from "../../../components/Footer/Footer";
import LogoColor from "../src/logo-tabsa-color.png";
import moment from "moment";
import ReactGA from 'react-ga';

import { useTranslation } from "react-i18next";

function PYPNItinerario() {
    const [t, i18n] = useTranslation("global");

    const [cruces, setCruces] = useState([]);
    const [crucesSuspendidos, setCrucesSuspendidos] = useState([]);
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);
    const [ruta, setRuta] = useState({});

    const [idaA, setIdaA] = useState({ data: [] });
    const [idaB, setIdaB] = useState({ data: [] });
    const [idaC, setIdaC] = useState({ data: [] });
    const [vueltaA, setVueltaA] = useState({ data: [] });
    const [vueltaB, setVueltaB] = useState({ data: [] });
    const [vueltaC, setVueltaC] = useState({ data: [] });

    const [nombreMesA, setnombreMesA] = useState("");
    const [nombreMesB, setnombreMesB] = useState("");
    const [nombreMesC, setnombreMesC] = useState("");

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname,
            location: window.location.href,
        });
        fetch(`${process.env.REACT_APP_API_BASEURL}/rutas/ruta/pyungay-pnatales`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setRuta(data.data);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });

        let firstDate = moment(new Date()).format("DD-MM-yyyy");
        let secondDate = moment(new Date().setDate(new Date().getDate() + 1)).format("DD-MM-yyyy");

        fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${firstDate}/embarcadero/0`, { method: "GET" }).then(firstDateResponse => {
            if (firstDateResponse.ok) {
                firstDateResponse.json().then(firstDateData => {

                    let newCruces = firstDateData.data.map((d) => ({
                        estado: d.estado_detalle.toLowerCase(),
                        origen: d.origen,
                        destino: d.destino,
                        hora: d.zarpeOriginal.split(" ")[1].substring(0, 5),
                        fecha: d.zarpeOriginal.split(" ")[0]
                    }))
                    setCruces(newCruces);

                    fetch(`https://api-cruce.tabsa.cl/api/cruce/20/fecha/${secondDate}/embarcadero/0`, { method: "GET" }).then(secondDateResponse => {
                        if (secondDateResponse.ok) {
                            secondDateResponse.json().then(secondDateData => {

                                let newSecondCruces = secondDateData.data.map((d) => ({
                                    estado: d.estado_detalle.toLowerCase(),
                                    origen: d.origen,
                                    destino: d.destino,
                                    hora: d.zarpeOriginal.split(" ")[1].substring(0, 5),
                                    fecha: d.zarpeOriginal.split(" ")[0]
                                }))

                                let concats = newCruces.concat(newSecondCruces);
                                setCruces(concats);

                                let newCrucesSuspendidos = [];

                                concats.map((d) => {
                                    if (d.estado === "suspendido" || d.estado === "cancelado") {
                                        newCrucesSuspendidos.push(d);
                                    }
                                });

                                setCrucesSuspendidos(newCrucesSuspendidos);
                                setShowCruce(newCrucesSuspendidos.length > 0)
                            })
                        }
                        else {
                            console.log(`ERROR ${secondDateResponse.url} status ${secondDateResponse.status} ${secondDateResponse.statusText}`)
                        }

                    })
                })
            }
            else {
                console.log(`ERROR ${firstDateResponse.url} status ${firstDateResponse.status} ${firstDateResponse.statusText}`)
            }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });

        fetch(`https://api-cruce.tabsa.cl/api/tramos`, { method: "GET" }).then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setRutas(data);
                })
            }
            else {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
        }).catch(error => {
            console.log(JSON.stringify(error));
        });

        let fechaA = new Date();
        setnombreMesA(fechaA);
        fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaA).format("YYYY-MM")}/40`, { method: "GET" }).then(response => {
            if (!response.ok) {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
            else {
                response.json().then(data => {

                    let idas = data.data.filter(d => d.id_tramo.substring(0, 1) === "4").filter(d => !d.pasado);
                    idas = idas.concat(idas.map((i) => i.intermedios).reduce((acc, val) => acc.concat(val))).sort((a, b) => {
                        if (a.zarpeOriginal > b.zarpeOriginal) {
                            return 1;
                        }
                        if (a.zarpeOriginal < b.zarpeOriginal) {
                            return -1;
                        }
                        return 0;
                    });

                    let vueltas = data.data.filter(d => d.id_tramo.substring(0, 1) === "5").filter(d => !d.pasado);
                    vueltas = vueltas.concat(vueltas.map((i) => i.intermedios).reduce((acc, val) => acc.concat(val))).sort((a, b) => {
                        if (a.zarpeOriginal > b.zarpeOriginal) {
                            return 1;
                        }
                        if (a.zarpeOriginal < b.zarpeOriginal) {
                            return -1;
                        }
                        return 0;
                    });

                    setIdaA({ fecha: fechaA, data: idas })
                    setVueltaA({ fecha: fechaA, data: vueltas })
                })
            }
        })

        let fechaB = new Date();
        fechaB.setDate(1);
        fechaB.setMonth(fechaB.getMonth() + 1);
        setnombreMesB(fechaB);
        fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaB).format("YYYY-MM")}/40`, { method: "GET" }).then(response => {
            if (!response.ok) {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
            else {
                response.json().then(data => {

                    let idas = data.data.filter(d => d.id_tramo.substring(0, 1) === "4").filter(d => !d.pasado);
                    idas = idas.concat(idas.map((i) => i.intermedios).reduce((acc, val) => acc.concat(val))).sort((a, b) => {
                        if (a.zarpeOriginal > b.zarpeOriginal) {
                            return 1;
                        }
                        if (a.zarpeOriginal < b.zarpeOriginal) {
                            return -1;
                        }
                        return 0;
                    });

                    let vueltas = data.data.filter(d => d.id_tramo.substring(0, 1) === "5").filter(d => !d.pasado);
                    vueltas = vueltas.concat(vueltas.map((i) => i.intermedios).reduce((acc, val) => acc.concat(val))).sort((a, b) => {
                        if (a.zarpeOriginal > b.zarpeOriginal) {
                            return 1;
                        }
                        if (a.zarpeOriginal < b.zarpeOriginal) {
                            return -1;
                        }
                        return 0;
                    });

                    setIdaB({ fecha: new Date(fechaB), data: idas })
                    setVueltaB({ fecha: new Date(fechaB), data: vueltas })
                })
            }
        })

        let fechaC = new Date();
        fechaC.setDate(1);
        fechaC.setMonth(fechaC.getMonth() + 2);
        setnombreMesC(fechaC);
        fetch(`https://api-cruce.tabsa.cl/api/cruce/fecha/mes/${moment(fechaC).format("YYYY-MM")}/40`, { method: "GET" }).then(response => {
            if (!response.ok) {
                console.log(`ERROR ${response.url} status ${response.status} ${response.statusText}`);
            }
            else {
                response.json().then(data => {

                    let idas = data.data.filter(d => d.id_tramo.substring(0, 1) === "4").filter(d => !d.pasado);
                    idas = idas.concat(idas.map((i) => i.intermedios).reduce((acc, val) => acc.concat(val))).sort((a, b) => {
                        if (a.zarpeOriginal > b.zarpeOriginal) {
                            return 1;
                        }
                        if (a.zarpeOriginal < b.zarpeOriginal) {
                            return -1;
                        }
                        return 0;
                    });

                    let vueltas = data.data.filter(d => d.id_tramo.substring(0, 1) === "5").filter(d => !d.pasado);
                    vueltas = vueltas.concat(vueltas.map((i) => i.intermedios).reduce((acc, val) => acc.concat(val))).sort((a, b) => {
                        if (a.zarpeOriginal > b.zarpeOriginal) {
                            return 1;
                        }
                        if (a.zarpeOriginal < b.zarpeOriginal) {
                            return -1;
                        }
                        return 0;
                    });

                    setIdaC({ fecha: new Date(fechaC), data: idas })
                    setVueltaC({ fecha: new Date(fechaC), data: vueltas })

                })
            }
        })


    }, []);

    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);

    function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
        setChatOpen(chat);
        setShowSelectLanguage(idiomas);
        setLoginOpen(login);
    }

    const viajeIdaA = idaA.data === 0 ? 0 : filtrarYAgruparPorViaje(idaA.data);
    const viajeIdaB = idaB.data === 0 ? 0 : filtrarYAgruparPorViaje(idaB.data);
    const viajeIdaC = idaC.data === 0 ? 0 : filtrarYAgruparPorViaje(idaC.data);
    const viajeVueltaA = idaA.data === 0 ? 0 : filtrarYAgruparPorViaje(vueltaA.data);
    const viajeVueltaB = idaA.data === 0 ? 0 : filtrarYAgruparPorViaje(vueltaB.data);
    const viajeVueltaC = idaA.data === 0 ? 0 : filtrarYAgruparPorViaje(vueltaC.data);

    if (Object.keys(viajeIdaA).length < Object.keys(viajeVueltaA).length) {

        const diferencia = Object.keys(viajeVueltaA).length - Object.keys(viajeIdaA).length;

        for (let i = 0; i < diferencia; i++) {
            viajeIdaA[`k${i}`] = [{}];  // Agrega un nuevo viaje vacío
        }
        
    }
    if (Object.keys(viajeIdaB).length < Object.keys(viajeVueltaB).length) {

        const diferencia = Object.keys(viajeVueltaB).length - Object.keys(viajeIdaB).length;

        for (let i = 0; i < diferencia; i++) {
            viajeIdaB[`k${i}`] = [{}];  // Agrega un nuevo viaje vacío
        }
    }

    if (Object.keys(viajeIdaC).length < Object.keys(viajeVueltaC).length) {

        const diferencia = Object.keys(viajeVueltaC).length - Object.keys(viajeIdaC).length;

        for (let i = 0; i < diferencia; i++) {
            viajeIdaC[`k${i}`] = [{}];  // Agrega un nuevo viaje vacío
        }
    }

    return (
        <div className="PYPNItinerario">
            <EstadoCruce clickOut={showItems} cruces={cruces} crucesSuspendidos={crucesSuspendidos} showCruce={showCruce} />
            <div className={`d-none d-lg-block slider-top-shadow${!showCruce ? "-off" : ""}`}>
                <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} showLogin={loginOpen} />
            </div>
            <div className="div-pypn-itinerario-s d-none d-lg-block" onClick={() => { showItems(false, false, false, false, false) }}>
                {
                    ruta.imgBack &&
                    <img className="img-pypn-itinerario-s" alt={"pypn-itinerario"} src={ruta.imgBack} />
                }
            </div>
            <div className="div-pypn-itinerario-body" onClick={() => { showItems(false, false, false, false, false) }}>
                <Container>
                    <Row className="justify-content-center d-block d-lg-none align-center">
                        <Col lg={6} sm={12} xs={12}>
                            <img alt="logo" className="logo-color-xs" src={LogoColor} />
                        </Col>
                    </Row>
                    <Row className="justify-content-left">
                        <Col lg={11} sm={11} xs={11}>
                            <p className="title-pypn-itinerario-s">{t("nuestros-servicios.pyungay-pnatales.itinerario.titulo")}</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-left">
                        <Col lg={6} md={6} sm={12} xs={12}>
                            <div className="titulo-mes">{t(`months.${moment(nombreMesA).format("MMM")}`)}</div>
                            <div className="disclaimer">⚠️{t("itinerarios.disclaimer")}</div>
                        </Col>

                    </Row>

                    <Row className="">
                        {Object.keys(viajeIdaA).map((id_viaje, index) => {
                            const vueltaId = Object.keys(viajeVueltaA)[index]; // Obtener el viaje de vuelta correspondiente

                            return (
                                <React.Fragment key={id_viaje}>
                                    {/* Tabla de Ida */}
                                    <Col lg={6} md={12} sm={12} xs={12}>

                                        {id_viaje.startsWith("k") ? null :
                                            <div className="table-itinerario">
                                                <div className="thead-itinerario">
                                                    <div className="th-itinerario-surnor">{t("itinerarios.sur-norte")}</div>
                                                </div>
                                                <div className="overflow-x-auto">
                                                    <div className="tbody-itinerario">
                                                        {viajeIdaA[id_viaje].map(({ id, id_tramo, origen, destino, nombre_embarcadero, presentacion, zarpeOriginal, horaLlegada, duracion }, idx) => (
                                                            <React.Fragment key={id}>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{t(`days.${moment(zarpeOriginal).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{moment(zarpeOriginal).format("DD")}</div>
                                                                    <div className="Columna embarque">({idx === 0 ? t(`itinerarios.zarpe`) : t(`itinerarios.salida`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{moment(zarpeOriginal).format("HH:mm")}</div>
                                                                    <div className="Columna lugar">{origen}</div>
                                                                </div>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{zarpeOriginal === horaLlegada ? "s/ info" : t(`days.${moment(horaLlegada).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("DD")}</div>
                                                                    <div className="Columna embarque">({t(`itinerarios.llegada`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("HH:mm")}* </div>
                                                                    <div className="Columna lugar">{destino}</div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>}
                                    </Col>

                                    {/* Tabla de Vuelta */}
                                    {vueltaId && (
                                        <Col lg={6} md={12} sm={12} xs={12}>

                                            <div className="table-itinerario">
                                                <div className="thead-itinerario">
                                                    <div className="th-itinerario-norsur">{t("itinerarios.norte-sur")}</div>
                                                </div>
                                                <div className="overflow-x-auto">
                                                    <div className="tbody-itinerario">
                                                        {viajeVueltaA[vueltaId].map(({ id, id_tramo, origen, destino, nombre_embarcadero, presentacion, zarpeOriginal, horaLlegada, duracion }, idx) => (
                                                            <React.Fragment key={id}>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{t(`days.${moment(zarpeOriginal).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{moment(zarpeOriginal).format("DD")}</div>
                                                                    <div className="Columna embarque">({idx === 0 ? t(`itinerarios.zarpe`) : t(`itinerarios.salida`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{moment(zarpeOriginal).format("HH:mm")}</div>
                                                                    <div className="Columna lugar">{origen}</div>
                                                                </div>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{zarpeOriginal === horaLlegada ? "s/ info" : t(`days.${moment(horaLlegada).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("DD")}</div>
                                                                    <div className="Columna embarque">({t(`itinerarios.llegada`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("HH:mm")}* </div>
                                                                    <div className="Columna lugar">{destino}</div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Row>

                    <Row className="justify-content-left">
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="titulo-mes">{t(`months.${moment(nombreMesB).format("MMM")}`)}</div>
                            <div className="disclaimer">⚠️{t("itinerarios.disclaimer")}</div>
                        </Col>
                    </Row>


                    <Row className="">
                        {Object.keys(viajeIdaB).map((id_viaje, index) => {
                            const vueltaId = Object.keys(viajeVueltaB)[index]; // Obtener el viaje de vuelta correspondiente

                            return (
                                <React.Fragment key={id_viaje}>
                                    {/* Tabla de Ida */}
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        {id_viaje.startsWith("k") ? null :
                                            <div className="table-itinerario">
                                                <div className="thead-itinerario">
                                                    <div className="th-itinerario-surnor">{t("itinerarios.sur-norte")}</div>
                                                </div>
                                                <div className="overflow-x-auto">
                                                    <div className="tbody-itinerario">
                                                        {viajeIdaB[id_viaje].map(({ id, id_tramo, origen, destino, nombre_embarcadero, presentacion, zarpeOriginal, horaLlegada, duracion }, idx) => (
                                                            <React.Fragment key={id}>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{t(`days.${moment(zarpeOriginal).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{moment(zarpeOriginal).format("DD")}</div>
                                                                    <div className="Columna embarque">({idx === 0 ? t(`itinerarios.zarpe`) : t(`itinerarios.salida`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{moment(zarpeOriginal).format("HH:mm")}</div>
                                                                    <div className="Columna lugar">{origen}</div>
                                                                </div>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{zarpeOriginal === horaLlegada ? "s/ info" : t(`days.${moment(horaLlegada).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("DD")}</div>
                                                                    <div className="Columna embarque">({t(`itinerarios.llegada`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("HH:mm")}* </div>
                                                                    <div className="Columna lugar">{destino}</div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>}
                                    </Col>

                                    {/* Tabla de Vuelta */}
                                    {vueltaId && (
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <div className="table-itinerario">
                                                <div className="thead-itinerario">
                                                    <div className="th-itinerario-norsur">{t("itinerarios.norte-sur")}</div>
                                                </div>
                                                <div className="overflow-x-auto">
                                                    <div className="tbody-itinerario">
                                                        {viajeVueltaB[vueltaId].map(({ id, id_tramo, origen, destino, nombre_embarcadero, presentacion, zarpeOriginal, horaLlegada, duracion }, idx) => (
                                                            <React.Fragment key={id}>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{t(`days.${moment(zarpeOriginal).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{moment(zarpeOriginal).format("DD")}</div>
                                                                    <div className="Columna embarque">({idx === 0 ? t(`itinerarios.zarpe`) : t(`itinerarios.salida`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{moment(zarpeOriginal).format("HH:mm")}</div>
                                                                    <div className="Columna lugar">{origen}</div>
                                                                </div>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{zarpeOriginal === horaLlegada ? "s/ info" : t(`days.${moment(horaLlegada).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("DD")}</div>
                                                                    <div className="Columna embarque">({t(`itinerarios.llegada`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("HH:mm")}* </div>
                                                                    <div className="Columna lugar">{destino}</div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Row>

                    <Row className="justify-content-left">
                        <Col lg={6} md={12} sm={12} xs={12}>
                            <div className="titulo-mes">{t(`months.${moment(nombreMesC).format("MMM")}`)}</div>
                            <div className="disclaimer">⚠️{t("itinerarios.disclaimer")}</div>
                        </Col>
                    </Row>
                    <Row className="">
                        {Object.keys(viajeIdaC).map((id_viaje, index) =>  {
                            const vueltaId = Object.keys(viajeVueltaC)[index]; // Obtener el viaje de vuelta correspondiente

                            return (
                                <React.Fragment key={id_viaje}>
                                    {/* Tabla de Ida */}
                                    <Col lg={6} md={12} sm={12} xs={12}>
                                        { id_viaje.startsWith("k") ? null :
                                            <div className="table-itinerario">
                                                <div className="thead-itinerario">
                                                    <div className="th-itinerario-surnor">{t("itinerarios.sur-norte")}</div>
                                                </div>
                                                <div className="overflow-x-auto">
                                                    <div className="tbody-itinerario">
                                                        {viajeIdaC[id_viaje].map(({ id, id_tramo, origen, destino, nombre_embarcadero, presentacion, zarpeOriginal, horaLlegada, duracion }, idx) => (
                                                            <React.Fragment key={id}>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{t(`days.${moment(zarpeOriginal).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{moment(zarpeOriginal).format("DD")}</div>
                                                                    <div className="Columna embarque">({idx === 0 ? t(`itinerarios.zarpe`) : t(`itinerarios.salida`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{moment(zarpeOriginal).format("HH:mm")}</div>
                                                                    <div className="Columna lugar">{origen}</div>
                                                                </div>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{zarpeOriginal === horaLlegada ? "s/ info" : t(`days.${moment(horaLlegada).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("DD")}</div>
                                                                    <div className="Columna embarque">({t(`itinerarios.llegada`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("HH:mm")}* </div>
                                                                    <div className="Columna lugar">{destino}</div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>}
                                    </Col>

                                    {/* Tabla de Vuelta */}
                                    {vueltaId && (
                                        <Col lg={6} md={12} sm={12} xs={12}>
                                            <div className="table-itinerario">
                                                <div className="thead-itinerario">
                                                    <div className="th-itinerario-norsur">{t("itinerarios.norte-sur")}</div>
                                                </div>
                                                <div className="overflow-x-auto">
                                                    <div className="tbody-itinerario">
                                                        {viajeVueltaC[vueltaId].map(({ id, id_tramo, origen, destino, nombre_embarcadero, presentacion, zarpeOriginal, horaLlegada }, idx) => (
                                                            <React.Fragment key={id}>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{t(`days.${moment(zarpeOriginal).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{moment(zarpeOriginal).format("DD")}</div>
                                                                    <div className="Columna embarque">({idx === 0 ? t(`itinerarios.zarpe`) : t(`itinerarios.salida`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{moment(zarpeOriginal).format("HH:mm")}</div>
                                                                    <div className="Columna lugar">{origen}</div>
                                                                </div>
                                                                <div className="fila">
                                                                    <div className="Columna fecha-mes">{zarpeOriginal === horaLlegada ? "s/ info" : t(`days.${moment(horaLlegada).format("ddd")}`)}</div>
                                                                    <div className="Columna fecha-dia">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("DD")}</div>
                                                                    <div className="Columna embarque">({t(`itinerarios.llegada`)})</div>
                                                                    <div className="Columna"><div className="route-container"><div className="route"></div><div className="icon"></div></div></div>
                                                                    <div className="Columna hora">{zarpeOriginal === horaLlegada ? "s/ info" : moment(horaLlegada).format("HH:mm")}* </div>
                                                                    <div className="Columna lugar">{destino}</div>
                                                                </div>
                                                            </React.Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </Row>



                </Container>
            </div>
            <Chat clickOut={showItems} chatOpen={chatOpen} />
            <Footer clickOut={showItems} />
            <VolverInicio Path={"/rutas/pyungay-pnatales"} Texto={"volver-inicio.servicios"} />

        </div>
    );
}

const filtrarYAgruparPorViaje = (data) => {

    let agrupados = "";

    if (!data) {
        return agrupados = "No hay viajes disponibles."
    } else {
        agrupados = data.reduce((acc, item) => {
            if (!acc[item.id_viaje]) {
                acc[item.id_viaje] = [];
            }
            acc[item.id_viaje].push(item);
            return acc;
        }, {});

        // Eliminamos los tramos intermedios y añadimos la duración del viaje
        Object.keys(agrupados).forEach(id_viaje => {
            agrupados[id_viaje] = agrupados[id_viaje] // Filtrar intermedios
                .filter(item => !["42", "43", "45", "52", "53", "55"].includes(item.id_tramo)) // Filtrar intermedios
                .map(item => {
                    const duracion = duracionViajes.find(d => d.id_tramo === item.id_tramo)?.duracion || 0;
                    const horaLlegada = moment(item.zarpeOriginal).add(duracion, "hours").format("YYYY-MM-DD HH:mm:ss"); // Sumar la duración

                    return {
                        ...item,
                        duracion,
                        horaLlegada
                    };
                });

            // Ordenamos los tramos dentro de cada viaje según la condición del id_tramo 41
            const contieneTramo41 = agrupados[id_viaje].some(item => item.id_tramo === "41");

            agrupados[id_viaje].sort((a, b) =>
                contieneTramo41
                    ? parseInt(a.id_tramo) - parseInt(b.id_tramo) // Ascendente si hay un 41
                    : parseInt(b.id_tramo) - parseInt(a.id_tramo) // Descendente si no hay 41
            );
        });

        return agrupados;
    }
    // Primero, agrupamos por id_viaje

};

// Definir duraciones de cada tramo
const duracionViajes = [
    { id_tramo: "41", duracion: 26 },
    { id_tramo: "42", duracion: 38 },
    { id_tramo: "43", duracion: 40 },
    { id_tramo: "44", duracion: 22 },
    { id_tramo: "45", duracion: 14 },
    { id_tramo: "46", duracion: 2 }, //FINAL
    
    { id_tramo: "51", duracion: 26 },
    { id_tramo: "52", duracion: 38 },
    { id_tramo: "53", duracion: 40 },
    { id_tramo: "54", duracion: 13 },
    { id_tramo: "55", duracion: 22 },
    { id_tramo: "56", duracion: 2 }
];

// Uso de la función


export default PYPNItinerario;

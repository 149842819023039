import "./Concurso.css";
import { useState, useEffect } from "react";
import { Col, Container, Row, Card, Alert } from "react-bootstrap";
import EstadoCruce from '../../components/EstadoCruce/EstadoCruce';
import Navegacion from "../../components/Navegacion/Navegacion";
import VolverInicio from "../../components/VolverInicio/VolverInicio";
import Footer from "../../components/Footer/Footer";
import Chat from "../../components/Chat/Chat";
import ImgQuienesSomos from "./src/portada.png";
import moment from "moment";
import ImgDocumento from "./src/documento.png";
import ImgFormulario from "./src/formulario-de-inscripcion.png";

import { useTranslation } from "react-i18next";

function QuienesSomos() {
    const [t, i18n] = useTranslation("global");
    const [showCruce, setShowCruce] = useState(false);
    const [rutas, setRutas] = useState([]);
    const [chatOpen, setChatOpen] = useState(false);
    const [showSelectLanguage, setShowSelectLanguage] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);

  function showItems(chat, calendarioIda, calendarioVuelta, idiomas, login) {
    setChatOpen(chat);
    setShowSelectLanguage(idiomas);
    setLoginOpen(login);
  }

    return (
        <div className="QuienesSomos">

          <div className={`slider-top-shadow${!showCruce ? "-off": ""}`}>
            <Navegacion clickOut={showItems} rutas={rutas} showSelectLanguage={showSelectLanguage} />
          </div>

          <div className="div-quienes-somos-s" clickOut={() => { showItems(false, false, false, false, false) }}>
            <img className="img-quienes-somos-t-s" alt={"quienes somos"} src={ImgQuienesSomos} />
          </div>
          <div className="div-quienes-somos-body" clickOut={() => { showItems(false, false, false, false, false) }}>
            <Container>
              <Row className="justify-content-center row-content-qss-s">
                <Col lg={10} md={11} sm={11} xs={11}>
                    <p className="title-quienes-somos-s">🚢 ¡Lleva tus proyectos más lejos con TABSA!</p>
                    <p className="p-quienes-somos-s">
                    
                    Transbordadora Austral Broom S.A. (TABSA) te invita a postular al Concurso de Pasajes de Cortesía 2025, una oportunidad única para que organizaciones sociales de Magallanes y Aysén (Caleta Tortel) puedan hacer realidad sus iniciativas a través del apoyo en conectividad marítima.
                    ¿Tienes un proyecto cultural, deportivo, ambiental o comunitario que necesita apoyo para trasladarse? Este llamado es para ti.
                    <br></br>
                    💡 TABSA te apoya con hasta $2.000.000 en pasajes anuales para que tu impacto llegue aún más lejos.

                    <br></br>
                    <br></br>
                    
                    <Row>
                      <Col sm={12} lg={6}>
                      👥 <b> ¿Quiénes pueden postular? </b>
                          <ul>
                            <li>Juntas de vecinos</li>
                            <li>Clubes del adulto mayor</li>
                            <li>Clubes deportivos</li>
                            <li>Organismos educacionales o culturales</li>
                            <li>ONG</li>
                            <li>Organizaciones de y para personas con discapacidad</li>
                            <li>Otras entidades sin fines de lucro con al menos 1 año de existencia</li>
                          </ul>
                          
                          🎯 <b> Categorías del concurso: </b>
                          <ul>
                            <li>Medio Ambiente y Salud</li>
                            <li>Educación y Cultura</li>
                            <li>Deporte y Recreación</li>
                          </ul>
                  
                          📝 <b>¿Qué necesitas para postular? </b>
                          <ul>
                            <li>Personalidad jurídica vigente</li>
                            <li>Certificados actualizados de vigencia y directorio</li>
                            <li>Declaración de aceptación de bases</li>
                            <li>Un proyecto claro que beneficie a tu comunidad</li>
                          </ul>
                        
                          📅 <b> Fechas clave: </b>
                          <ul>
                            <li>Postulación: 1 al 20 de abril 2025</li>
                            <li>Resultados: 2 de mayo 2025</li>
                            <li>Entrega de pasajes: Desde el 5 de mayo 2025</li>
                          </ul>
                      </Col>

                      <Col sm={12} lg={6}>
                        <Row>
                          <Col className="col-card-servicio" lg={4}>
                            <Card  className="card-servicio custom-card"  onClick={() => {
                              window.location.href = "https://recursos.tabsa.cl/storage/documents/SX9qkByt.pdf"
                            }}>
                              <Row className="card-img-color">
                                <Col lg={12}  >
                                  <img className="img-concurso"  src={ImgDocumento} />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="div-detalle-servicio" lg={12}>
                                  <p className="p-nombre-servicio"><span className="p-b">DESCARGAR </span></p>
                                  <p className="p-nombre-servicio"><span className="p-b">BASES</span></p>
                                </Col>
                              </Row>
                            </Card>
                          </Col>

                          <Col className="col-card-servicio" lg={4}>
                            <Card className="card-servicio custom-card" onClick={() => {
                              window.location.href = "https://recursos.tabsa.cl/storage/documents/RiqmUGMt.docx"
                            }}>
                              <Row>
                                <Col lg={12}>
                                  <img className="img-concurso" src={ImgFormulario} />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="div-detalle-servicio" lg={12}>
                                  <p className="p-nombre-servicio"><span className="p-b">DESCARGAR</span></p>
                                  <p className="p-nombre-servicio"><span className="p-b">FORMULARIO</span></p>

                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          <Alert variant="warning">
                            📧 Envía tu formulario y bases firmadas a <a href="mailto:pasajesconcursables@tabsa.cl"> pasajesconcursables@tabsa.cl </a>
                          </Alert>
                        </Row>
                      </Col>
                    </Row>
                    
                    <br></br>
                    

                    </p>

                </Col>
              </Row>

            </Container>
          </div>
          <Chat clickOut={showItems} chatOpen={chatOpen}/>
          <Footer clickOut={showItems} />
          <VolverInicio Path={"/inicio"} Texto={"volver-inicio.volver"} />
        </div>
    );
}

export default QuienesSomos;
